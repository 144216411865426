import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Nav } from 'react-bootstrap';

export default function Logout(props) {
	const { logout } = useAuth0();
	return (
		<Nav.Item>
			<Nav.Link
				className='px-0 py-2 p-lg-4'
				style={{ fontWeight: 600 }}
				onClick={() =>
					logout({
						returnTo: process.env.REACT_APP_CALLBACK,
					})
				}
			>
				Log Out
			</Nav.Link>
		</Nav.Item>
	);
}
