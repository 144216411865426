import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink, useParams } from 'react-router-dom';

export function WhatWeDoLang() {
	const [lang, setLang] = useState(null);
	let { id } = useParams();

	useEffect(() => {
		if (id === 'en' || id === 'de') {
			setLang(id);
		}
	}, [lang, id]);

	return (
		<>
			{lang ? (
				<>
					<div className='bg-white'>
						<Container className='py-3'>
							<Row className='mb-5'>
								<Col xs='auto'>
									<div className='p-0 p-lg-5'>
										<NavLink
											to='/what-we-do'
											className='bg-primary rounded-circle d-flex align-items-center justify-content-center p-2'
											style={{
												width: '1.5rem',
												height: '1.5rem',
											}}
										>
											<FontAwesomeIcon
												icon={faChevronLeft}
												color='white'
												className='position-relative'
												style={{ right: '1px' }}
											/>
										</NavLink>
									</div>
								</Col>
							</Row>
							<Row className='pb-5 py-lg-0 mb-4'>
								<Col xs={12} lg={9} xl={6}>
									<div className='p-0 p-lg-5'>
										<h2 className='display-1 text-uppercase text-primary'>
											{lang === 'en' && (
												<>What We Do...</>
											)}
											{lang === 'de' && (
												<>Was Wir Tun...</>
											)}
										</h2>
										<p className='lead fw-semibold'>
											{lang === 'en' && (
												<>
													eFlex delivers the simplest
													path to digital
													transformation. Our powerful
													web-based solutions provide
													process control with digital
													work instructions,
													traceability, and data
													collection for every part. A
													lean competitive advantage
													to maximize quality and
													throughput on the plant
													floor.
												</>
											)}
											{lang === 'de' && (
												<>
													eFlex bietet den einfachsten
													Weg zu digitale
													Transformation. Unsere
													Mächtigen webbasierte
													Lösungen bieten Prozess
													Steuerung mit digitalen
													Arbeitsanweisungen,
													Rückverfolgbarkeit und
													Datenerhebung für jeder
													Teil. Ein schlanker
													Wettbewerbsvorteil um
													Qualität und Durchsatz zu
													maximieren der Fabrik.
												</>
											)}
										</p>
									</div>
								</Col>
								<Col xs={12} lg={9} xl={6}>
									<div className='p-0 p-lg-5'>
										<div
											className='position-relative w-100 mb-2'
											style={{ paddingBottom: '56.25%' }}
										>
											<img
												src='https://490686.fs1.hubspotusercontent-na1.net/hubfs/490686/Images/Photos/photoStill_jem2.jpg'
												alt='eflex jem digital work instructions'
												className='w-100 h-100 position-absolute'
												style={{ top: 0, left: 0 }}
											/>
										</div>
										<i>
											{lang === 'en' && (
												<>
													eFlex's digital work
													instructions.
												</>
											)}
											{lang === 'de' && (
												<>
													Die digitalen
													Arbeitsanweisungen von
													eFlex.
												</>
											)}
										</i>
									</div>
								</Col>
							</Row>
							<Row className='flex-row-reverse pb-3 py-lg-0 mb-5'>
								<Col xs={12} lg={9} xl={6}>
									<div className='p-0 p-lg-5'>
										<h2 className='display-1 text-uppercase text-primary'>
											{lang === 'en' && (
												<>What We Offer...</>
											)}
											{lang === 'de' && (
												<>Was Wir Anbieten...</>
											)}
										</h2>
										<p className='lead fw-semibold'>
											{lang === 'en' && (
												<>
													Our Manufacturing Integrated
													Platform, or MIP for short,
													is a modern IIoT software
													application. We strive to
													empower manufacturers to
													join the Industry 4.0
													movement with cost
													effective, easy-to-use
													solutions, permitting them
													to become agile, data driven
													and best in class.
												</>
											)}
											{lang === 'de' && (
												<>
													Unsere Manufacturing
													Integrated Platform, oder
													kurz MIP, ist ein modernes
													IIoT Computerprogramm. Wir
													bemühen uns Ermächtigen Sie
													Hersteller, sich dem
													anzuschließen Industrie 4.0
													Bewegung mit Kosten
													effektive,
													benutzerfreundliche
													Lösungen, damit sie agil
													werden, Daten gefahren und
													Klassenbester.
												</>
											)}
										</p>
									</div>
								</Col>
								<Col xs={12} lg={9} xl={6}>
									<div className='p-0 p-lg-5'>
										<div
											className='position-relative w-100'
											style={{ paddingBottom: '56.25%' }}
										>
											<iframe
												src='https://www.youtube.com/embed/mVNw5SOmjkQ'
												title='YouTube video player'
												frameBorder='0'
												allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
												allowFullScreen
												className='position-absolute w-100 h-100 border-0'
												style={{ top: 0, left: 0 }}
											></iframe>
										</div>
									</div>
								</Col>
							</Row>
							<Row className='pb-5 py-lg-0 mb-5'>
								<Col xs={12} lg={9} xl={6}>
									<div className='p-0 p-lg-5'>
										<h2 className='display-1 text-uppercase text-primary'>
											{lang === 'en' && (
												<>Simple Setup...</>
											)}
											{lang === 'de' && (
												<>Was Wir Anbieten...</>
											)}
										</h2>
										<p className='lead fw-semibold'>
											{lang === 'en' && (
												<>
													It’s all connected so it
													doesn’t need months to get
													started. Simply turn on the
													solutions you need now and
													add more as you grow.
												</>
											)}
											{lang === 'de' && (
												<>
													Es ist alles miteinander
													verbunden, also braucht es
													nicht Monate um anzufangen.
													Einfach einschalten die
													Lösungen, die Sie jetzt
													benötigen, und fügen Sie
													weitere hinzu wie du wächst.
												</>
											)}
										</p>
									</div>
								</Col>
								<Col xs={12} lg={9} xl={6}>
									<div className='p-0 p-lg-5'>
										<img
											src='https://490686.fs1.hubspotusercontent-na1.net/hubfs/490686/Images/Internal%20Graphics/contImprove.png'
											alt='eFlex continuous improvement engineering'
											width='150'
											className='mb-5'
										/>
										<h5 className='text-uppercase mb-2'>
											{lang === 'en' && <>1. Configure</>}
											{lang === 'de' && (
												<>1. Konfigurieren</>
											)}
										</h5>
										<p className='lead fw-semibold mb-5'>
											{lang === 'en' && (
												<>
													MIP is simple to configure
													without any programming. No
													more complex layers.
												</>
											)}
											{lang === 'de' && (
												<>
													MIP ist einfach ohne
													irgendwelche zu
													konfigurieren
													Programmierung. Keine
													komplexen Schichten mehr.
												</>
											)}
										</p>
										<h5 className='text-uppercase mb-2'>
											{lang === 'en' && <>2. Connect</>}
											{lang === 'de' && <>2. Verbinden</>}
										</h5>
										<p className='lead fw-semibold mb-5'>
											{lang === 'en' && (
												<>
													Easy, plug-n-play, IoT
													device connect.
													Automatically display,
													verify and collect device
													level data at the station
													level.
												</>
											)}
											{lang === 'de' && (
												<>
													Einfacher
													Plug-and-Play-Anschluss von
													IoT-Geräten. Automatisch
													anzeigen, überprüfen und
													Sammeln Sie Daten auf
													Geräteebene an der Station
													Stufe.
												</>
											)}
										</p>
										<h5 className='text-uppercase mb-2'>
											{lang === 'en' && <>3. Control</>}
											{lang === 'de' && <>3. Kontrolle</>}
										</h5>
										<p className='lead fw-semibold mb-5'>
											{lang === 'en' && (
												<>
													Complexity under control!
													Advanced process control for
													low and high mix assembly.
													Easily error proof your
													operations.
												</>
											)}
											{lang === 'de' && (
												<>
													Komplexität im Griff!
													Fortschrittlich
													Prozesssteuerung für Low-
													und High-Mix Montage. Leicht
													fehlersicher Ihre
													Operationen.
												</>
											)}
										</p>
										<h5 className='text-uppercase mb-2'>
											{lang === 'en' && <>4. Collect</>}
											{lang === 'de' && <>4. Sammeln</>}
										</h5>
										<p className='lead fw-semibold'>
											{lang === 'en' && (
												<>
													Collect process data and
													cycle times down to the task
													level. Quickly identify
													problem areas and
													improvement initiatives.
												</>
											)}
											{lang === 'de' && (
												<>
													Sammeln Sie Prozessdaten und
													Taktzeiten bis auf die
													Aufgabenebene. Schnell
													identifizieren
													Problembereiche und
													Verbesserung Initiativen.
												</>
											)}
										</p>
									</div>
								</Col>
							</Row>
							<Row className='pb-5 py-lg-0'>
								<Col
									xs={12}
									lg={9}
									xl={6}
									className='mx-lg-auto mb-3'
								>
									<div className='p-0 p-lg-5'>
										<h2 className='display-1 text-uppercase text-primary'>
											{lang === 'en' && (
												<>Let Us Grow With You.</>
											)}
											{lang === 'de' && (
												<>
													Lassen Sie Uns Mit Ihnen
													Wachsen.
												</>
											)}
										</h2>
										<p className='lead mb-5'>
											{lang === 'en' && (
												<>
													No matter where you are in
													your digital journey, eFlex
													has you covered.
												</>
											)}
											{lang === 'de' && (
												<>
													Egal, wo Sie sich in Ihrem
													digitalen befinden Reise,
													eFlex hat Sie abgedeckt.
												</>
											)}
										</p>
										<Row>
											<Col xs={12} lg='auto'>
												<Button
													href='https://www.eflexsystems.com/contact'
													variant='primary'
													className='text-uppercase text-white w-100 py-3'
												>
													{lang === 'en' && (
														<>Contact Us</>
													)}
													{lang === 'de' && (
														<>kontaktiere uns</>
													)}
												</Button>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className='bg-black'>
						<Container className='py-5'>
							<Row className='gy-3'>
								<Col
									xs={12}
									lg={9}
									xl={6}
									className='mx-lg-auto'
								>
									<div className='p-0 p-lg-5'>
										<h2 className='display-1 text-uppercase text-white'>
											{lang === 'en' && (
												<>Save this page!</>
											)}
											{lang === 'de' && (
												<>Speichern Sie diese Seite!</>
											)}
										</h2>
										<p className='text-white mb-5'>
											{lang === 'en' && (
												<>
													<strong>
														Tap and hold the image
														below to save it to your
														photos.
													</strong>{' '}
													You can then access this
													page by tapping the link
													from your photos.
												</>
											)}
											{lang === 'de' && (
												<>
													<strong>
														Tippen und halten Sie
														das Bild unten um es in
														Ihren Fotos zu
														speichern.
													</strong>{' '}
													Sie können dann auf diese
													Seite zugreifen, indem Sie
													Tippen Sie auf den Link von
													Ihrem Fotos.
												</>
											)}
										</p>
										<Row>
											<Col xs={12}>
												<div
													className='position-relative w-100 mb-2'
													style={{
														paddingBottom: '100%',
													}}
												>
													<img
														src='https://490686.fs1.hubspotusercontent-na1.net/hubfs/490686/Images/Internal%20Graphics/qrCode_whatWeDo_cameraRoll2.jpg'
														alt='eflex what-we-do information qr code'
														className='w-100 h-100 position-absolute'
														style={{
															top: 0,
															left: 0,
														}}
													/>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</>
			) : (
				<>
					<p>404</p>
				</>
			)}
		</>
	);
}

export default function WhatWeDo() {
	return (
		<>
			<div className='vh-100' style={{ backgroundColor: '#003460' }}>
				<Container className='h-100 py-3'>
					<Row className='flex-column justify-content-center align-items-center h-100 gy-3'>
						<Col xs='auto'>
							<div className='mb-5'>
								<img
									src='https://490686.fs1.hubspotusercontent-na1.net/hubfs/490686/Logos/eflexLogo_white.png'
									alt='eFlex Systems White Logo'
									width='225'
								/>
							</div>
						</Col>
						<Col xs={12}>
							<Button
								variant='outline-white'
								as={NavLink}
								to='en'
								className='fw-normal border-1 w-100 position-relative p-3'
							>
								United States - English
								<div
									className='d-flex align-items-center position-absolute w-100 h-100 p-3'
									style={{ top: 0, left: 0 }}
								>
									<img
										src='https://490686.fs1.hubspotusercontent-na1.net/hubfs/490686/Images/Internal%20Graphics/us.svg'
										alt='United States - English Flag'
										width='20'
									/>
									<FontAwesomeIcon
										icon={faChevronRight}
										color='white'
										className='ms-auto'
									/>
								</div>
							</Button>
						</Col>
						<Col xs={12}>
							<Button
								variant='outline-white'
								as={NavLink}
								to='de'
								className='fw-normal border-1 w-100 position-relative p-3'
							>
								Deutschland - Deutsch
								<div
									className='d-flex align-items-center position-absolute w-100 h-100 p-3'
									style={{ top: 0, left: 0 }}
								>
									<img
										src='https://490686.fs1.hubspotusercontent-na1.net/hubfs/490686/Images/Internal%20Graphics/de.svg'
										alt='United States - English Flag'
										width='20'
									/>
									<FontAwesomeIcon
										icon={faChevronRight}
										color='white'
										className='ms-auto'
									/>
								</div>
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}
