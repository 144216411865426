import './styles/globals.scss';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap';
import * as React from 'react';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Masthead from './components/Masthead';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import WhatWeDo, { WhatWeDoLang } from './pages/WhatWeDo';
// import Home from './pages/Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import qs from 'qs';

// const Header = React.lazy(() => import('./components/Header'));
// const Navbar = React.lazy(() => import('./components/Navbar'));
// const Masthead = React.lazy(() => import('./components/Masthead'));
// const Footer = React.lazy(() => import('./components/Footer'));

const Home = React.lazy(() => import('./pages/Home'));
const Default = React.lazy(() => import('./pages/Default'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Videos = React.lazy(() => import('./pages/Videos'));
const Video = React.lazy(() => import('./pages/Video'));
const Author = React.lazy(() => import('./pages/Author'));
const Manual = React.lazy(() => import('./pages/Manual'));
const InstallFiles = React.lazy(() => import('./pages/InstallFiles'));
const Updates = React.lazy(() => import('./pages/Updates'));

export default function App() {
	const { isLoading, user, isAuthenticated, getAccessTokenSilently } =
		useAuth0();
	const [userMetadata, setUserMetadata] = useState(0);
	const [userRoles, setUserRoles] = useState(null);
	const [userVersions, setUserVersions] = useState(null);
	const [selectedVersion, setSelectedVersion] = useState(null);
	const [newUser, setNewUser] = useState(null);

	// * Add global prop for window width. (needed for navbar, video component etc)

	// Populate useMetadata with accessToken
	useEffect(() => {
		if (!isLoading) {
			// Auth0 PATCH request (update user) on user_metadata.
			const patchUserMetadata = async (body) => {
				const accessToken = await getAccessTokenSilently({
					audience: `https://${process.env.REACT_APP_DOMAIN}/api/v2/`,
					scope: 'read:users read:current_user read:users_app_metadata update:users update:users_app_metadata update:current_user_metadata',
				});

				const userDetailsByIdUrl = `https://${process.env.REACT_APP_DOMAIN}/api/v2/users/${user.sub}`;

				const metadataResponse = await fetch(userDetailsByIdUrl, {
					method: 'PATCH',
					body: JSON.stringify({
						user_metadata: body,
					}),
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'content-type': 'application/json',
					},
				});

				await metadataResponse;
			};

			const getUserMetadata = async () => {
				try {
					const accessToken = await getAccessTokenSilently({
						audience: `https://${process.env.REACT_APP_DOMAIN}/api/v2/`,
						scope: 'read:users read:current_user read:users_app_metadata update:users update:users_app_metadata update:current_user_metadata',
					});

					const userDetailsByIdUrl = `https://${process.env.REACT_APP_DOMAIN}/api/v2/users/${user.sub}`;

					const metadataResponse = await fetch(userDetailsByIdUrl, {
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					});

					const { user_metadata, app_metadata } =
						await metadataResponse.json();

					// Get User Metadata
					setUserMetadata(user_metadata);

					// Get User Roles
					setUserRoles(app_metadata.authorization.roles);

					// Filter Roles to Get List of Just Version Access
					const versions = app_metadata.authorization.roles.filter(
						function (findVersion) {
							return findVersion.includes('Version');
						}
					);
					setUserVersions(versions);

					// New User?
					if (versions.length === 0) {
						setNewUser(true);

						if (user_metadata['alertedSlack'] !== true) {
							patchUserMetadata({
								alertedSlack: true,
							}).then(() => {
								console.log('alert slack');

								// Alert Slack
								const post = async (args) => {
									const result = await axios({
										method: 'POST',
										url: 'https://slack.com/api/chat.postMessage',
										data: qs.stringify(args),
										headers: {
											'content-type':
												'application/x-www-form-urlencoded',
										},
									});
									return result;
								};
								post({
									token: process.env.REACT_APP_SLACK_TOKEN,
									channel: '#learning-center',
									text: `*New User Request. <!subteam^SA5FX5UMA|supportteam>*\nPlease verify user and select appropriate account access.\n\n*Name:* ${user_metadata.firstName} ${user_metadata.lastName}\n*User:* ${user.nickname}\n*Email:* ${user.email}\n*Phone Number:* ${user_metadata.phone}\n*Company:* ${user_metadata.company}\n*Job Title:* ${user_metadata.jobTitle}\n*Location:* ${user_metadata.location}`,
								});
							});
						}
					} else {
						setNewUser(false);
						patchUserMetadata({
							alertedSlack: true,
						});
					}

					// Find the length to set the selected version to the most recent Version.
					const versionsLength = versions.length;
					setSelectedVersion(versions[versionsLength - 1]);
				} catch (e) {
					console.log(e.message);
				}
			};

			getUserMetadata();
		}
	}, [getAccessTokenSilently, user?.sub, user, isLoading]);

	function handleVersion(version) {
		setSelectedVersion(version);
	}

	function Page(props) {
		return (
			<>
				<Header />
				<Masthead
					isLoading={isLoading}
					isAuthenticated={isAuthenticated}
					nickname={isAuthenticated && user.nickname}
					userVersions={userVersions}
					selectedVersion={selectedVersion}
					newUser={newUser}
				/>

				{/* Physical Links */}
				{isAuthenticated && (
					<Navbar
						picture={user.picture}
						isAuthenticated={isAuthenticated}
						userVersions={userVersions}
						selectedVersion={selectedVersion}
						handleVersion={handleVersion}
						newUser={newUser}
					/>
				)}
				<React.Suspense fallback={<div>Loading...</div>}>
					{props.children}
				</React.Suspense>
				<Footer />
			</>
		);
	}

	if (isLoading) {
		return (
			<div className='d-flex align-item-center justify-content-center vw-100 vh-100 bg-white'>
				<FontAwesomeIcon
					icon={faCircleNotch}
					className='text-gray-300 my-auto'
					size='3x'
					spin
				/>
			</div>
		);
	} else {
		return (
			<>
				{/* Routing */}
				<Routes>
					<Route
						path='/'
						element={
							<Page>
								<Home isAuthenticated={isAuthenticated} />
							</Page>
						}
					/>
					{isAuthenticated ? (
						<>
							<Route
								path='profile'
								element={
									<Page>
										<Profile
											isAuthenticated={isAuthenticated}
											user={user}
											userMetadata={userMetadata}
											userRoles={userRoles}
											newUser={newUser}
										/>
									</Page>
								}
							/>
							{!newUser && (
								<>
									<Route
										path='videos'
										element={
											<Page>
												<Videos
													selectedVersion={
														selectedVersion
													}
												/>
											</Page>
										}
									/>
									<Route
										path='videos/:id'
										element={
											<Page>
												<Video
													isAuthenticated={
														isAuthenticated
													}
													userMetadata={userMetadata}
													selectedVersion={
														selectedVersion
													}
												/>
											</Page>
										}
									/>
									<Route
										path='videos/author/:id'
										element={
											<Page>
												<Author
													selectedVersion={
														selectedVersion
													}
												/>
											</Page>
										}
									/>
									<Route
										path='manual'
										element={
											<Page>
												<Manual
													selectedVersion={
														selectedVersion
													}
												/>
											</Page>
										}
									/>
									<Route
										path='installfiles'
										element={
											<Page>
												<InstallFiles
													selectedVersion={
														selectedVersion
													}
												/>
											</Page>
										}
									/>

									<Route
										path='updates/:id'
										element={
											<Page>
												<Updates
													selectedVersion={
														selectedVersion
													}
												/>
											</Page>
										}
									/>
								</>
							)}
						</>
					) : (
						<Route
							path='/:id'
							element={
								<Page>
									<Default />
								</Page>
							}
						/>
					)}
					<Route path='what-we-do' element={<WhatWeDo />} />
					<Route
						path='what-we-do/:id'
						element={
							<>
								<Header />
								<WhatWeDoLang />
							</>
						}
					/>
				</Routes>
			</>
		);
	}
}
