import {
	faMapMarkerAlt,
	faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

export const navigation_footer = [
	{
		children: [
			{
				title: 'Location',
				icon: faMapMarkerAlt,
				children: [
					{
						title: [
							'eFlex Systems',
							'210 W. Tienken Rd.',
							'Rochester Hills, MI 48306',
						],
						href: 'https://www.google.com/maps/dir//eFlex+Systems,+210+W+Tienken+Rd,+Rochester+Hills,+MI+48306/@42.6484084,-83.1327323,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8824e9a0cb9311d7:0xe750ed70bdc9b9eb!2m2!1d-83.1383362!2d42.6959042',
					},
				],
			},
			{
				title: 'Contact Sales',
				children: [
					{
						title: 'sales@eflexsystems.com',
						icon: faEnvelope,
						href: 'mailto:sales@eflexsystems.com',
					},
				],
			},
		],
	},
	{
		title: 'Quick Links',
		children: [
			{
				title: 'Request a Demo',
				href: 'https://www.eflexsystems.com/request-a-demo',
			},
			{
				title: 'Contact Us',
				href: 'https://www.eflexsystems.com/contact',
			},
			{
				title: 'Platform',
				href: 'https://www.eflexsystems.com/platform',
			},
			{
				title: 'About Us',
				href: 'https://www.eflexsystems.com/about/about-eflex-systems',
			},
			{
				title: 'Blog',
				href: 'https://www.eflexsystems.com/lean-manufacturing-blog',
			},
			{
				title: 'Resources',
				href: 'https://www.eflexsystems.com/resource-library',
			},
			{
				title: 'Support',
				href: 'https://www.eflexsystems.com/contact-customer-support',
			},
		],
	},
	{
		title: 'Support',
		children: [
			{
				title: 'Client Portal',
				href: 'https://eflexsupport.com/',
			},
			{
				title: 'Open a Ticket',
				href: 'https://epiccare.epicor.com/epiccare',
			},
			{
				title: 'Terms of Service',
				href: 'https://www.eflexsystems.com/terms-and-services',
			},
			{
				title: 'Privacy Policy',
				href: 'https://www.eflexsystems.com/terms-and-services#privacy-policy',
			},
		],
	},
];
