import React, { useEffect, useState } from 'react';
// import Login from './Login';
import Logout from './Logout';
import { Container, Row, Col, Nav, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { updatesMap } from '../utils/updatesMap';

function NavItem(props) {
	return (
		<Col xs='auto'>
			<Nav.Item>
				<Nav.Link
					eventKey={props.href.replace('/', '')}
					className={props.windowLtLg ? '' : 'px-0 py-2 p-lg-4'}
					as={NavLink}
					to={props.href}
					disabled={props.disabled || props.newUser ? true : false}
				>
					{props.label}
				</Nav.Link>
			</Nav.Item>
		</Col>
	);
}

export default function Navbar(props) {
	const [windowLtLg, setWindowLtLg] = useState({
		matches: window.innerWidth < 992 ? true : false,
	});

	useEffect(() => {
		let mediaQuery = window.matchMedia('(max-width: 992px)');
		mediaQuery.addEventListener('change', setWindowLtLg);

		return () => mediaQuery.removeEventListener('change', setWindowLtLg);
	}, [windowLtLg]);

	const handleChange = (e) => {
		props.handleVersion(e);
	};

	return (
		<>
			<div
				id='navbar'
				className={`${
					windowLtLg && windowLtLg.matches
						? 'bg-white border-bottom border-gray-300 sticky-top py-2'
						: 'bg-white pt-5'
				}`}
				style={{ top: '4.55rem' }}
			>
				<Container>
					<Nav
						className={`row align-items-center g-1 g-lg-0 px-0 px-lg-5 ${
							windowLtLg && windowLtLg.matches ? 'nav-pills' : ''
						}`}
					>
						{windowLtLg && windowLtLg.matches ? (
							<NavItem
								href='/profile'
								label='Profile'
								windowLtLg={windowLtLg && windowLtLg.matches}
							/>
						) : (
							<Col xs='auto'>
								<Nav.Item>
									<Nav.Link
										eventKey='profile'
										as={NavLink}
										to='/profile'
										className='p-0'
									>
										<img
											src={props.picture}
											className='rounded-circle me-3'
											style={{ width: '4rem' }}
											alt='eFlex User Profile'
										/>
									</Nav.Link>
								</Nav.Item>
							</Col>
						)}

						<NavItem
							href='/videos'
							label='Video Tutorials'
							windowLtLg={windowLtLg && windowLtLg.matches}
							newUser={props.newUser}
						/>
						<NavItem
							href='/manual'
							label='Version Manual'
							windowLtLg={windowLtLg && windowLtLg.matches}
							newUser={props.newUser}
						/>
						<NavItem
							href='/installfiles'
							label='Install Files'
							windowLtLg={windowLtLg && windowLtLg.matches}
							newUser={props.newUser}
						/>
						<NavItem
							href={
								`/updates/` +
								updatesMap[updatesMap.length - 1].version
							}
							label='Updates'
							windowLtLg={windowLtLg && windowLtLg.matches}
							newUser={props.newUser}
							disabled={props.selectedVersion !== 'Version 8'}
						/>
						<Col xs='auto' className='ms-lg-auto'>
							<Row className='align-items-center g-1'>
								<Col xs='auto'>
									{props.selectedVersion &&
										props.userVersions && (
											<>
												{props.userVersions.length >
													1 && (
													<Dropdown
														onSelect={handleChange}
													>
														<Dropdown.Toggle
															id='version-dropdown'
															variant='outline-gray-800'
															className='p-2 px-3'
														>
															<span>
																{
																	props.selectedVersion
																}
																<FontAwesomeIcon
																	className='ms-2'
																	size='sm'
																	icon={
																		faAngleDown
																	}
																/>
															</span>
														</Dropdown.Toggle>
														<Dropdown.Menu
															aria-labelledby='version-dropdown'
															className='p-0'
														>
															{props.userVersions.map(
																(
																	version,
																	i
																) => {
																	return (
																		<React.Fragment
																			key={
																				i
																			}
																		>
																			<Dropdown.Item
																				className='px-3 py-2'
																				eventKey={
																					version
																				}
																			>
																				<span
																					style={
																						windowLtLg &&
																						windowLtLg.matches
																							? {
																									fontSize:
																										'.875rem',
																							  }
																							: null
																					}
																				>
																					{
																						version
																					}
																				</span>
																			</Dropdown.Item>
																		</React.Fragment>
																	);
																}
															)}
														</Dropdown.Menu>
													</Dropdown>
												)}
											</>
										)}
								</Col>
								<Col xs='auto'>
									<Logout />
								</Col>
							</Row>
						</Col>
					</Nav>
				</Container>
			</div>
		</>
	);
}
