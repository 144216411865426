const images = {
	photoStock17_eflexTraining_gradient: {
		original: require('../assets/images/photoStock17_eflexTraining_gradient.jpg'),
		'800jpg': require('../assets/images/_compressed/photoStock17_eflexTraining_gradient/photoStock17_eflexTraining_gradient-800.jpg'),
		'800webp': require('../assets/images/_compressed/photoStock17_eflexTraining_gradient/photoStock17_eflexTraining_gradient-800.webp'),
		'1200webp': require('../assets/images/_compressed/photoStock17_eflexTraining_gradient/photoStock17_eflexTraining_gradient-800.webp'),
		lazyload: require('../assets/images/_compressed/photoStock17_eflexTraining_gradient/photoStock17_eflexTraining_gradient-lazyload.jpg'),
	},
	'1573s-lazyload': {
		1: require('../assets/images/_compressed/1573s_1/1573s_1-lazyload.jpg'),
		2: require('../assets/images/_compressed/1573s_2/1573s_2-lazyload.jpg'),
		3: require('../assets/images/_compressed/1573s_3/1573s_3-lazyload.jpg'),
		4: require('../assets/images/_compressed/1573s_4/1573s_4-lazyload.jpg'),
		5: require('../assets/images/_compressed/1573s_5/1573s_5-lazyload.jpg'),
		6: require('../assets/images/_compressed/1573s_6/1573s_6-lazyload.jpg'),
		7: require('../assets/images/_compressed/1573s_7/1573s_7-lazyload.jpg'),
		8: require('../assets/images/_compressed/1573s_8/1573s_8-lazyload.jpg'),
		9: require('../assets/images/_compressed/1573s_9/1573s_9-lazyload.jpg'),
		10: require('../assets/images/_compressed/1573s_10/1573s_10-lazyload.jpg'),
		11: require('../assets/images/_compressed/1573s_11/1573s_11-lazyload.jpg'),
		12: require('../assets/images/_compressed/1573s_12/1573s_12-lazyload.jpg'),
		13: require('../assets/images/_compressed/1573s_13/1573s_13-lazyload.jpg'),
		14: require('../assets/images/_compressed/1573s_14/1573s_14-lazyload.jpg'),
		15: require('../assets/images/_compressed/1573s_15/1573s_15-lazyload.jpg'),
		16: require('../assets/images/_compressed/1573s_16/1573s_16-lazyload.jpg'),
		17: require('../assets/images/_compressed/1573s_17/1573s_17-lazyload.jpg'),
		18: require('../assets/images/_compressed/1573s_18/1573s_18-lazyload.jpg'),
		19: require('../assets/images/_compressed/1573s_19/1573s_19-lazyload.jpg'),
		20: require('../assets/images/_compressed/1573s_20/1573s_20-lazyload.jpg'),
		21: require('../assets/images/_compressed/1573s_21/1573s_21-lazyload.jpg'),
		22: require('../assets/images/_compressed/1573s_22/1573s_22-lazyload.jpg'),
		23: require('../assets/images/_compressed/1573s_23/1573s_23-lazyload.jpg'),
		24: require('../assets/images/_compressed/1573s_24/1573s_24-lazyload.jpg'),
		25: require('../assets/images/_compressed/1573s_25/1573s_25-lazyload.jpg'),
		26: require('../assets/images/_compressed/1573s_26/1573s_26-lazyload.jpg'),
		27: require('../assets/images/_compressed/1573s_27/1573s_27-lazyload.jpg'),
		28: require('../assets/images/_compressed/1573s_28/1573s_28-lazyload.jpg'),
	},
	'1573s-800': {
		1: require('../assets/images/_compressed/1573s_1/1573s_1-800.jpg'),
		2: require('../assets/images/_compressed/1573s_2/1573s_2-800.jpg'),
		3: require('../assets/images/_compressed/1573s_3/1573s_3-800.jpg'),
		4: require('../assets/images/_compressed/1573s_4/1573s_4-800.jpg'),
		5: require('../assets/images/_compressed/1573s_5/1573s_5-800.jpg'),
		6: require('../assets/images/_compressed/1573s_6/1573s_6-800.jpg'),
		7: require('../assets/images/_compressed/1573s_7/1573s_7-800.jpg'),
		8: require('../assets/images/_compressed/1573s_8/1573s_8-800.jpg'),
		9: require('../assets/images/_compressed/1573s_9/1573s_9-800.jpg'),
		10: require('../assets/images/_compressed/1573s_10/1573s_10-800.jpg'),
		11: require('../assets/images/_compressed/1573s_11/1573s_11-800.jpg'),
		12: require('../assets/images/_compressed/1573s_12/1573s_12-800.jpg'),
		13: require('../assets/images/_compressed/1573s_13/1573s_13-800.jpg'),
		14: require('../assets/images/_compressed/1573s_14/1573s_14-800.jpg'),
		15: require('../assets/images/_compressed/1573s_15/1573s_15-800.jpg'),
		16: require('../assets/images/_compressed/1573s_16/1573s_16-800.jpg'),
		17: require('../assets/images/_compressed/1573s_17/1573s_17-800.jpg'),
		18: require('../assets/images/_compressed/1573s_18/1573s_18-800.jpg'),
		19: require('../assets/images/_compressed/1573s_19/1573s_19-800.jpg'),
		20: require('../assets/images/_compressed/1573s_20/1573s_20-800.jpg'),
		21: require('../assets/images/_compressed/1573s_21/1573s_21-800.jpg'),
		22: require('../assets/images/_compressed/1573s_22/1573s_22-800.jpg'),
		23: require('../assets/images/_compressed/1573s_23/1573s_23-800.jpg'),
		24: require('../assets/images/_compressed/1573s_24/1573s_24-800.jpg'),
		25: require('../assets/images/_compressed/1573s_25/1573s_25-800.jpg'),
		26: require('../assets/images/_compressed/1573s_26/1573s_26-800.jpg'),
		27: require('../assets/images/_compressed/1573s_27/1573s_27-800.jpg'),
		28: require('../assets/images/_compressed/1573s_28/1573s_28-800.jpg'),
	},
};

export default images;
