import React from 'react';
import $ from 'jquery';
import {
	Container,
	Row,
	Col,
	Navbar,
	Dropdown,
	Nav,
	Button,
} from 'react-bootstrap';
import { navigation } from '../utils/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {
	faBars,
	faPlus,
	faMinus,
	faArrowRight,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';

class NavDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = { collapsed: true };
	}

	handleDropdownExpand(e) {
		this.setState({ collapsed: !e });
	}

	render() {
		return (
			<Dropdown onToggle={(e) => this.handleDropdownExpand(e)}>
				<Dropdown.Toggle
					href={this.props.ltDesktop ? null : this.props.item.href}
					variant='link'
					className='nav-link ff-titillium-web border-0 w-100 p-4'
				>
					{this.props.item.title}
					{this.props.ltDesktop ? (
						<FontAwesomeIcon
							className='ms-auto me-1'
							size='sm'
							icon={this.state.collapsed ? faPlus : faMinus}
						/>
					) : (
						<FontAwesomeIcon
							className='ms-2'
							size='sm'
							icon={faAngleDown}
						/>
					)}
				</Dropdown.Toggle>

				<Dropdown.Menu renderOnMount className='pb-4 pb-lg-0'>
					{this.props.item.children && (
						<Container fluid>
							<Row className='p-4 p-lg-5'>
								{this.props.item.children.map((child, j) => {
									return (
										<React.Fragment key={j}>
											<Col
												xs='6'
												lg
												style={{
													minWidth: '20vw',
												}}
											>
												<p className='small text-truncate fw-bold text-uppercase border-gray-200 border-bottom border-lg-0 py-3 mb-3'>
													{child.title}
												</p>
												{child.children && (
													<>
														{child.children.map(
															(grandchild, k) => {
																return (
																	<React.Fragment
																		key={k}
																	>
																		<Dropdown.Item
																			className='small text-truncate px-0 py-1'
																			href={
																				grandchild.href
																			}
																		>
																			{
																				grandchild.title
																			}
																		</Dropdown.Item>
																	</React.Fragment>
																);
															}
														)}
													</>
												)}
											</Col>
										</React.Fragment>
									);
								})}
							</Row>
						</Container>
					)}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = { windowWidth: window.innerWidth, collapsed: true };
	}

	handleResize = (e) => {
		this.setState({ windowWidth: window.innerWidth });
		if (992 <= this.state.windowWidth) {
			$('body').removeClass('position-fixed');
		} else {
			let toggler = document.getElementsByClassName('navbar-toggler');
			if (!toggler[0].classList.contains('collapsed')) {
				$('body').addClass('position-fixed');
			}
		}
	};

	handleToggle() {
		let toggler = document.getElementsByClassName('navbar-toggler');
		if (toggler[0].classList.contains('collapsed')) {
			$('body').addClass('position-fixed');
			this.setState({ collapsed: false });
		} else {
			$('body').removeClass('position-fixed');
			this.setState({ collapsed: true });
		}
	}

	componentDidMount() {
		// Add slideDown animation to Bootstrap dropdown when expanding.
		$('.dropdown').on('show.bs.dropdown', function () {
			$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		});

		// Add slideUp animation to Bootstrap dropdown when collapsing.
		$('.dropdown').on('hide.bs.dropdown', function () {
			$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
		});

		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.addEventListener('resize', this.handleResize);
	}

	render() {
		return (
			<>
				<div id='header' className='bg-white sticky-top'>
					<Container fluid='lg' className='g-0 g-lg-3'>
						<Navbar expand='lg' className='px-0 px-lg-5 py-0'>
							<Navbar.Brand
								href='https://www.eflexsystems.com'
								className='py-0 ps-3 ps-lg-0'
								style={{ width: '7rem' }}
							>
								<img
									className='w-100'
									width='34'
									src='https://www.eflexsystems.com/hubfs/Logos/epicor_eflex.png'
									alt='eFlex Systems Epicor Logo'
								/>
							</Navbar.Brand>
							<Navbar.Toggle
								onClick={this.handleToggle.bind(this)}
								aria-controls='offcanvasNavbar'
								className='border-none p-4'
							>
								<FontAwesomeIcon
									icon={
										this.state.collapsed ? faBars : faTimes
									}
									size='lg'
								/>
							</Navbar.Toggle>
							<Navbar.Collapse className='w-100'>
								{navigation.map((item, i) => {
									return (
										<React.Fragment key={i}>
											{!item.children ? (
												<Nav.Link href={item.href}>
													{item.title}
													{992 >
														this.state
															.windowWidth && (
														<FontAwesomeIcon
															className='ms-auto me-1'
															size='sm'
															icon={faArrowRight}
														/>
													)}
												</Nav.Link>
											) : (
												<NavDropdown
													item={item}
													ltDesktop={
														992 >
														this.state.windowWidth
													}
												/>
											)}
										</React.Fragment>
									);
								})}
								<Col xs='12' lg='auto' className='ms-lg-auto'>
									<Button
										href='https://www.eflexsystems.com/request-a-demo'
										variant='primary-light'
										size='sm'
										className='text-start text-lg-center w-100 p-4 p-lg-2 px-lg-3'
									>
										Request Demo
									</Button>
								</Col>
								<Col xs='12' lg='auto' className='ms-lg-2'>
									<Button
										href='https://www.eflexsystems.com/contact-customer-support'
										variant='outline-gray-800'
										size='sm'
										className='text-start text-lg-center w-100 p-4 p-lg-2 px-lg-3'
									>
										Contact Us
									</Button>
								</Col>
							</Navbar.Collapse>
						</Navbar>
					</Container>
				</div>
			</>
		);
	}
}
