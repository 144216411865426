import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Images from '../utils/images';

export default function Masthead(props) {
	const { logout, loginWithRedirect } = useAuth0();

	useEffect(() => {}, [props.newUser]);

	return (
		<div className='position-relative d-flex align-items-center masthead'>
			<div
				className='responsive-clip-path position-absolute w-100 h-100 d-none d-lg-block'
				style={{
					zIndex: '-1',
					boxSizing: 'border-box',
					pointerEvents: 'none',
					top: 0,
				}}
			>
				<img
					width='400'
					height='225'
					alt='Training in an Industry 4.0 manufacturing environment.'
					src={
						Images['photoStock17_eflexTraining_gradient'][
							'original'
						]
					}
				/>
			</div>
			<Container>
				<Row className='py-5'>
					<Col xs={12} lg={8} xl={6}>
						<div className='p-0 p-lg-5'>
							<Row className='flex-column justify-content-end gy-3'>
								{!props.isLoading && (
									<>
										{props.isAuthenticated && (
											<Col xs='auto'>
												<h3 className='text-white mb-0 fw-normal'>
													Welcome
													{props.newUser
														? ','
														: ' back,'}
													&nbsp;
													<Button
														as={Link}
														to='/profile'
														variant='link'
														className='link-white p-0'
														style={{
															fontSize: 'inherit',
															fontWeight:
																'inherit',
														}}
													>
														{props.nickname}
													</Button>
													.
												</h3>
												<small className='text-white fst-italic'>
													Not you? &nbsp;
													<Button
														variant='link'
														className='link-white fst-italic p-0'
														style={{
															fontSize: 'inherit',
															fontWeight:
																'inherit',
														}}
														onClick={() =>
															logout({
																returnTo:
																	process.env
																		.REACT_APP_CALLBACK,
															})
														}
													>
														Log out
													</Button>
													.
												</small>
											</Col>
										)}
									</>
								)}
								<Col xs='auto'>
									<h1 className='display-1 text-white text-uppercase mb-1'>
										Client Portal
									</h1>
									{!props.isAuthenticated && (
										<p className='lead text-white mb-lg-5'>
											As a valued Epicor client, we provide
											you with the materials to help you
											learn and navigate the features we
											offer within our app. Request access
											by creating an account.
										</p>
									)}
								</Col>

								{props.isAuthenticated && (
									<>
										{props.selectedVersion ? (
											<Col xs='auto'>
												<p className='text-white mb-0'>
													You have access to{' '}
													<span className='fw-bold'>
														{props.userVersions
															.length > 1 ? (
															<>
																multiple
																versions
															</>
														) : (
															<>
																{
																	props.selectedVersion
																}
															</>
														)}
														.
													</span>
												</p>
											</Col>
										) : (
											<>
												{props.newUser ? (
													<Col xs='auto'>
														<p className='lead text-white'>
															This account
															currently has no
															permissions. We are
															working with our
															team to verify that
															you are an existing
															client with Epicor.
															Check your email or
															check here shortly.
															In the meantime,{' '}
															<a
																href='https://www.eflexsystems.com/contact'
																target='_blank'
																rel='noreferrer'
															>
																contact us
															</a>{' '}
															if you have any
															questions or
															concerns.
														</p>
													</Col>
												) : (
													<Col xs='auto'>
														<FontAwesomeIcon
															icon={faCircleNotch}
															size='lg'
															className='text-gray-400'
															spin
														/>
													</Col>
												)}
											</>
										)}
									</>
								)}

								{!props.isAuthenticated && (
									<>
										<Col xs='auto'>
											<Row className='g-3'>
												<Col xs={12} lg='auto'>
													<Button
														variant='primary-light'
														className='w-100 text-uppercase py-2 py-lg-3'
														onClick={() =>
															loginWithRedirect({
																mode: 'signUp',
															})
														}
													>
														Request Access
													</Button>
												</Col>
												<Col xs={12} lg='auto'>
													<Button
														variant='outline-white'
														className='w-100 text-uppercase py-2 py-lg-3'
														onClick={() =>
															loginWithRedirect()
														}
													>
														Log in with access
													</Button>
												</Col>
											</Row>
										</Col>
									</>
								)}
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
