// import { Row, Col } from 'react-bootstrap';
import Images from '../utils/images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Definition(props) {
	return (
		<span>
			<span className='fw-bold'>{props.value}:&nbsp;</span>
			{props.children}
		</span>
	);
}

function Image(props) {
	return (
		<div className='py-3 mb-3'>
			<div
				style={{
					width: props.width + 'px',
					height: props.height + 'px',
					maxWidth: '100%',
				}}
				className='bg-gray-100'
			>
				<LazyLoadImage
					effect='blur'
					alt={props.alt}
					src={Images[props.updateId + '-800'][props.imageId]}
					placeholderSrc={
						Images[props.updateId + '-lazyload'][props.imageId]
					}
					width={props.width}
					height={props.height}
					style={{ maxWidth: '100%', objectFit: 'contain' }}
				/>
			</div>
			{props.description ? (
				<p className='fst-italic mt-1 mb-0'>{props.alt}</p>
			) : null}
		</div>
	);
}

function Link(props) {
	return (
		<a
			href={props.href}
			target='_blank'
			rel='noreferrer'
			className='fw-normal'
		>
			{props.label}
		</a>
	);
}

function Code(props) {
	return <code>{props.value}</code>;
}

// function CodeBlock(props) {
// 	return (
// 		<pre>
// 			<Code value={props.value} />
// 		</pre>
// 	);
// }

// Last Object in the array with be the default Navigation for 'Updates' in the Navbar.
export const updatesMap = [
	{
		version: '8.0.20220701-1612s',
		date: 'july-2022',
		sections: [
			{
				heading: 'Whats New?',
				content: (
					<ul>
						<li>
							<a href='#tasks'>Tasks</a>: many changes...
							<ul>
								<li>
									<a href='#printer-tasks'>Printer Tasks</a>:
									added dynamic variables.
								</li>
								<li>
									<a href='#decision-tasks'>Decision Tasks</a>
									: added a{' '}
									<i>
										"Require a Decision Button to be
										Selected"
									</i>{' '}
									checkbox option.
								</li>
								<li>
									<a href='#barcode-tasks'>Barcode Tasks</a>:
									added regular Expression usage.
								</li>
								<li>
									<a href='#torque-tasks'>Torque Tasks</a>:
									some new features. Support for custom units,
									expiration functionality, and more.
								</li>
								<li>Bug fixes for various tasks.</li>
							</ul>
						</li>
						<li>
							A part's model code and description are in more
							areas throughout the app.
						</li>
						<li>
							<a href='#business-intelligence-reports'>
								Business Intelligence
							</a>
							: added many new types of reports.
						</li>
						<li>
							<a href='#oee'>OEE Shift Schedules</a>: brand new
							interface.
						</li>
						<li>
							<a href='#security'>Security</a>: Some changes to
							make security more robust.
						</li>
						<li>Fixed many other bugs across the app.</li>
					</ul>
				),
			},
			{
				heading: 'Tasks',
				content: (
					<ul>
						<li>
							Fixed a bug where tasks could not be copied when
							their type was changed.
						</li>
						<li>
							Fixed a bug where active parallel tasks would
							prevent restarting previous tasks.
						</li>
					</ul>
				),
				subsections: [
					{
						subheading: 'Printer Tasks',
						subcontent: (
							<ul>
								<li>
									Print Tasks can now print dynamic variables
									in the ZPL code. The following are included:
									<ul>
										<li>
											<Definition value='Serial number'>
												<Code value='{serialNumber}' />
											</Definition>
										</li>
										<li>
											<Definition value='Current user'>
												<Code value='{userName}' />
											</Definition>
										</li>
										<li>
											<Definition value='Current date'>
												<Code value='{dateTime}' />
											</Definition>
										</li>
										<li>
											<Definition value='Model code'>
												<Code value='{modelCode}' />
											</Definition>
										</li>
										<li>
											<Definition value='Model description'>
												<Code value='{modelDescription}' />
											</Definition>
										</li>
										<li>
											<Definition value='Process data from Button, PLC, and Node RED tasks'>
												<Code value='{PD_processData}' />
											</Definition>
										</li>
										<li>
											<Definition value='Custom identifier'>
												<Code value='{CI_<CUSTOM IDENTIFIER (CAMEL CASE)>}' />
											</Definition>
										</li>
										<li>
											<Definition value='Barcode string'>
												<Code value='{BC_<BARCODE TASK NAME (CAMEL CASE)>}' />
											</Definition>
										</li>
										<li>
											<Definition value='Process data'>
												<Code value='{BC_<PROCESS DATA NAME (CAMEL CASE)>}' />
											</Definition>
										</li>
										<li>
											<Definition value='BOM source data'>
												<ul>
													<li>
														BOM component name:{' '}
														<Code value='{<COMPONENT NAME (CAMEL CASE)>PnComponentName}' />
													</li>
													<li>
														COM component select
														option:{' '}
														<Code value='{<COMPONENT NAME (CAMEL CASE)>PnOptionName}' />
													</li>
													<li>
														BOM component value:{' '}
														<Code value='{<COMPONENT NAME (CAMEL CASE)>PnOptionValue}' />
													</li>
												</ul>
											</Definition>
										</li>
									</ul>
								</li>
							</ul>
						),
					},
					{
						subheading: 'Decision Tasks',
						subcontent: (
							<ul>
								<li>
									<p>
										Decision Tasks can now require a
										decision to be selected for passing or
										failing the task. The checkbox now
										appears underneath the <i>Enabled</i>{' '}
										checkbox.
									</p>
									<Image
										updateId='1573s'
										imageId={25}
										alt='eFlex requiring a Decision Task to be selected for passing/failing.'
										width='400'
										height='274'
									/>
								</li>
								<li>
									Fixed a bug where Decision labels would
									populate the <i>Process Data</i> tab in{' '}
									<i>Part History</i> when not selected during
									a part's run.
									<Image
										updateId='1573s'
										imageId={21}
										alt='Prior to this update: eFlex not requiring a Decision Task to be selected for passing/failing.'
										width='600'
										height='241'
										description={true}
									/>
									<Image
										updateId='1573s'
										imageId={23}
										alt='After this update: eFlex requiring a Decision Task to be selected for passing/failing.'
										width='600'
										height='199'
										description={true}
									/>
								</li>
							</ul>
						),
					},
					{
						subheading: 'Barcode Tasks',
						subcontent: (
							<>
								<ul>
									<li>
										Can now use Regular Expressions to
										accept scanned "String to Read" values.
										This allows for more validation of the
										string to read using{' '}
										<Link
											label='RegEx'
											href='https://regexr.com/'
										/>
										.
									</li>
								</ul>
							</>
						),
					},
					{
						subheading: 'Button Tasks',
						subcontent: (
							<>
								<ul>
									<li>
										Fixed a bug where Button Task Process
										Data Units wouldn't show in{' '}
										<i>Reports</i>.
									</li>
									<li>
										Units assigned to process data on Button
										Tasks are now visible under their{' '}
										<i>Part History Report</i>.
										<Image
											updateId='1573s'
											imageId={14}
											alt='The "Serial" value of "YAS-23" has a unit of "Mk. 1". The "Speed" value of "1000" has a unit of "fpm".'
											width='600'
											height='300'
											description={true}
										/>
									</li>
								</ul>
							</>
						),
					},
					{
						subheading: 'Torque Tasks',
						subcontent: (
							<>
								<ul>
									<li>
										The <i>Torque Label</i> field now allows
										for setting custom units for torque
										values. This label will show next to the
										torque value on <i>JEM</i> and{' '}
										<i>Reports</i>. For instance, 9.3 Nm.
										<Image
											updateId='1573s'
											imageId={15}
											alt='eFlex Torque Task - Torque Label allows custom units.'
											width='600'
											height='198'
										/>
									</li>
									<li>
										Can now configure the{' '}
										<i>expiration date</i> and the{' '}
										<i>expiration warning threshold</i> on
										the <i>Hardware</i> page for torque
										tools.
										<Image
											updateId='1573s'
											imageId={27}
											alt='Setting torque tool expiration date and expiration warning threshold in the eFlex app.'
											width='600'
											height='103'
										/>
										The user is now warned in JEM when a
										tool is nearing calibration expiration
										you set:
										<Image
											updateId='1573s'
											imageId={6}
											alt='JEM alerting that the torque tool is nearing the calibration expiration date.'
											width='600'
											height='296'
										/>
										The Torque Task is also automatically
										rejected when the tool's calibration has
										expired, requiring a change in its{' '}
										<i>Hardware</i> configuration before
										operating as normal:
										<Image
											updateId='1573s'
											imageId={13}
											alt='JEM alerting that the torque tool is nearing the calibration expiration date.'
											width='600'
											height='200'
										/>
									</li>
									<li>
										Rejected torque outputs are now logged
										in chronological order on the related
										Torque Task in <i>JEM</i>. All bolt
										rejects shown on <i>JEM</i> as well as
										in <i>Reports</i>. Once the bolt passes,
										reject results will collapse. Click to
										expand the results.
										<Image
											updateId='1573s'
											imageId={9}
											alt='JEM rejected Torque Task list. Click to expand.'
											width='400'
											height='478'
										/>
									</li>
									<li>
										Fixed a bug where <i>Part History</i>{' '}
										exports that included certain rejected
										Torque Tasks would incorrectly list the
										task as Good.
									</li>
								</ul>
							</>
						),
					},
					{
						subheading: 'Image Capture Tasks',
						subcontent: (
							<>
								<ul>
									<li>
										Fixed a bug where part serial numbers
										could not contain special characters.
									</li>
									<li>
										Fixed a bug where Image Tasks would
										produce darkened images under certain
										conditions.
									</li>
								</ul>
							</>
						),
					},
				],
			},
			{
				heading: 'Node-RED',
				content: (
					<ul>
						<li>
							Node-RED Tasks now allow for manipulation of iframe
							focus, allowing automatic selection of fields for
							string input.
						</li>
						<li>
							Node-RED build data can now be downloaded from the
							BOM to a Node-RED Task. This will make it easier to
							use the data in the Node-RED flows.
						</li>
					</ul>
				),
			},
			{
				heading: 'Resource Utility',
				subsections: [
					{
						subheading: 'Netdata',
						subcontent: (
							<ul>
								<li>
									<p>
										Monitor hardware, resource consumption,
										and network activity with Netdata.
									</p>
									<p>
										Netdata is an open-source data
										visualization platform that allows for
										live monitoring of resource usage such
										as CPU and memory, as well as network
										activity such as packet activity, TCP
										connections, and socket activity. These
										metrics are shown with live, easy to
										interpret charts.
									</p>
									<p>
										You can access Netdata by appending
										“/netdata” to the end of your URL. For
										example: 127.0.0.1/netdata
									</p>
									<p>
										An example of live socket and packet
										activity:
									</p>
									<Image
										updateId='1573s'
										imageId={10}
										alt='Live socket and packet activity from Netdata.'
										width='800'
										height='447'
									/>
									<Image
										updateId='1573s'
										imageId={20}
										alt="An overview of the application's performance displayed in Netdata"
										width='800'
										height='155'
										description={true}
									/>
								</li>
							</ul>
						),
					},
				],
			},
			{
				heading: 'Business Intelligence Reports',
				content: (
					<ul>
						<li>Improved performance of Business Intelligence.</li>
					</ul>
				),
				subsections: [
					{
						subheading: 'Station P-Chart',
						subcontent: (
							<ul>
								<li>
									Added a Station P-Chart report that displays
									the average reject rate based on search
									criteria.
									<Image
										updateId='1573s'
										imageId={4}
										alt='An example of an eFlex Station P-Chart Report.'
										width='800'
										height='354'
										description={true}
									/>
								</li>
							</ul>
						),
					},
					{
						subheading: 'Andon Report',
						subcontent: (
							<ul>
								<li>
									<p>
										Added reports for Andon Part Counts and
										Andon Station Summary.
									</p>
									<p>
										The Andon Part Counts page shows the
										quantities of passed and rejected parts
										per station, as well as a cumulative
										count on the bottom of the screen. The
										Andon Station Summary page shows live
										station information such as current
										model, OEE state, faults, current
										station state, and current station cycle
										time.
									</p>
									<Image
										updateId='1573s'
										imageId={26}
										alt='An example of an eFlex Andon Counts page.'
										width='800'
										height='356'
										description={true}
									/>
								</li>
							</ul>
						),
					},
					{
						subheading: 'Process Data Report',
						subcontent: (
							<ul>
								<li>
									Added a Process Data report which graphs the
									average numerical process data.
									<Image
										updateId='1573s'
										imageId={8}
										alt='An example of an eFlex Process Data report.'
										width='800'
										height='361'
										description={true}
									/>
								</li>
							</ul>
						),
					},
					{
						subheading: 'OEE Report',
						subcontent: (
							<ul>
								<li>
									Added an OEE States History report that logs
									station state information.
								</li>
								<li>
									Added an OEE Fault Code Summary page that
									displays fault code durations per code.
									<Image
										updateId='1573s'
										imageId={22}
										alt='An example of an eFlex OEE Fault Code Summary report.'
										width='800'
										height='362'
										description={true}
									/>
								</li>
							</ul>
						),
					},
					{
						subheading: 'Production Report',
						subcontent: (
							<ul>
								<li>
									Added a Production Report that displays the
									passed and rejected parts within a given
									time range.
									<Image
										updateId='1573s'
										imageId={17}
										alt='An example of an eFlex Production passed/rejected report.'
										width='800'
										height='362'
										description={true}
									/>
								</li>
								<li>
									Added a "Total Production Average for a
									Model" report.
								</li>
							</ul>
						),
					},
					{
						subheading: 'Cycle Time Report',
						subcontent: (
							<ul>
								<li>
									Added a Cycle Time Report that displays the
									average cycle time, per station and model,
									across the application. This report also
									includes the percentage of cycles that
									exceed the established target time.
									<Image
										updateId='1573s'
										imageId={5}
										alt='An example of an eFlex station/model Cycle Time report.'
										width='800'
										height='359'
										description={true}
									/>
								</li>
							</ul>
						),
					},
					{
						subheading: 'Serial Number Report',
						subcontent: (
							<ul>
								<li>
									Added a Serial Number Report that displays
									data for a specific serial number, including
									process data and cycle time information.
									<Image
										updateId='1573s'
										imageId={1}
										alt='An example of an eFlex Serial Number report.'
										width='800'
										height='361'
										description={true}
									/>
								</li>
							</ul>
						),
					},
				],
			},
			{
				heading: 'Part History',
				content: (
					<ul>
						<li>
							A part's model code and description are now shown in{' '}
							<i>Part History</i> reports.
							<Image
								updateId='1573s'
								imageId={18}
								alt='Model "A" with description "YAS Key Arm" is the
										model information associated to serial
										"A1653418205565".'
								width='800'
								height='352'
								description={true}
							/>
						</li>
						<li>
							<i>Advanced Criteria</i> search dropdowns are
							streamlined application-wide.
							<Image
								updateId='1573s'
								imageId={24}
								alt='Advanced Criteria search dropdowns for Part History Reports'
								width='800'
								height='310'
							/>
						</li>
						<li>
							Fixed a bug where passed task quantity would affect{' '}
							<i>Rerun Rejects Only</i> from showing every task in
							their <i>Part History</i> report.
						</li>
						<li>
							Fixed a bug where station selection dropdown would
							get cut off on{' '}
							<i>Part History Advanced Search Criteria</i>.
						</li>
					</ul>
				),
			},
			{
				heading: 'Improved Performance',
				content: (
					<ul>
						<li>
							eFlex now supports exceedingly large number of tasks
							in stations.
							<ul>
								<li>
									Large Stations can be managed as expected.
								</li>
								<li>
									BOM source opening optimizations prevent
									timeouts for large stations.
								</li>
							</ul>
						</li>
						<li>
							Task, Station, and Model copying optimizations now
							accelerate copying processes.
						</li>
						<li>
							Right-hand corner green configuration notifications
							are now hidden to prevent excessive clutter when
							making configuration changes.
						</li>
						<li>
							Audit logs now show more detail for bulk
							configuration changes such as copying stations.
						</li>
						<li>
							Browser-level warnings When attempting to redirect
							or reload the page with unsaved changes. This
							further prevents accidentally discarding unsaved
							configuration.
						</li>
					</ul>
				),
			},
			{
				heading: 'JEM',
				content: (
					<ul>
						<li>
							A part's model code and description are now shown in
							the <i>JEM</i> model selection dropdown.
							<Image
								updateId='1573s'
								imageId={16}
								alt="Every selectable model shows it's associated model code and description."
								width='400'
								height='238'
								description={true}
							/>
						</li>
						<li>
							Optimized and accelerated the loading for task and{' '}
							<i>JEM</i> configuration pages.
						</li>
						<li>
							Fixed a bug where page numbers configured for hosted
							PDF work instructions were not working.
						</li>
						<li>
							Fixed a bug where <i>Rerun Rejects Only</i> would
							not apply to previously disabled tasks.
						</li>
						<li>
							Fixed a <i>HOLD</i> bug where the station cycle
							timer would restart to 0 when it was set to{' '}
							<i>HOLD</i> and then <i>UNHOLD</i>.
						</li>
						<li>
							Fixed a task sequence bug where multi-sequence tasks
							would not occur in the order configured.
						</li>
						<li>
							Fixed a bug where custom identifiers would not show
							as expected in subsequent stations per part.
						</li>
						<li>
							Fixed a bug where parts rejected after passing would
							satisfy station prerequisites. Station prerequisites
							should not allow parts that are currently rejected
							to be loaded, regardless of if they were passed
							and/or loading into that station previously.
						</li>
						<li>
							Fixed a bug where disabled tasks would prevent a
							part from being repaired. Parts that were rejected
							while a task was disabled in its run can now be
							repaired in the <i>Repair</i> page, changing it's
							status as expected.
						</li>
						<li>
							Fixed a bug where <i>Dynamic Work Instructions</i>{' '}
							would not work with Torque Tasks.{' '}
							<i>Dynamic Work Instructions</i> should now work
							with Torque Tasks as intended.
						</li>
					</ul>
				),
			},
			{
				heading: 'Work Instruction Editor',
				content: (
					<ul>
						<li>
							Fixed a bug where <i>Work Instruction</i>{' '}
							configuration would sometimes display either no
							items in the library or only the currently selected
							image. The <i>Work Instruction</i> configuration
							will now properly show all related items in the
							library.
						</li>
						<li>
							Fixed a bug where support for 3D file formats for{' '}
							<i>Work Instructions</i> such as STL, glTF, glb, and
							obj was missing. Support for these file formats
							should now work as intended.
						</li>
					</ul>
				),
			},
			{
				heading: 'OEE',
				subsections: [
					{
						subheading: 'Shift Schedules',
						subcontent: (
							<ul>
								<li>
									Schedules are now configured and managed
									using a new UI.
									<Image
										updateId='1573s'
										imageId={3}
										alt='New OEE Shift Scheduler in the July 2022 release.'
										width='800'
										height='442'
										description={true}
									/>
								</li>
								<li>
									Some new features include the ability to:
									<ul>
										<li>
											Schedule the entire week in one
											interface.
										</li>
										<li>
											Configure an expiration date for the
											schedule.
										</li>
										<li>
											View old schedules under the{' '}
											<i>Archived</i> section.
										</li>
										<li>
											Configure a schedule to apply to
											individual stations and groups.
										</li>
										<li>
											Intuitively configure recurring
											schedules.
										</li>
									</ul>
								</li>
							</ul>
						),
					},
				],
			},
			{
				heading: 'Administration',
				content: (
					<ul>
						<li>
							Non-admin users can now navigate to the homepage
							using the eFlex logo in the upper left-hand corner.
						</li>
					</ul>
				),
			},
			{
				heading: 'Image Management',
				content: (
					<ul>
						<li>
							Fixed a bug where images could not be exported from
							the <i>eVision Image Retrieval</i> page.
						</li>
					</ul>
				),
			},
			{
				heading: 'Hardware Page',
				content: (
					<ul>
						<li>
							Fixed a bug where the yellow hardware badges would
							not list all associated tasks on hover. Hovering
							over this yellow badge will now properly display
							links to each station currently using that hardware.
							<Image
								updateId='1573s'
								imageId={2}
								alt='Hardware configuration yellow badges display links to all stations using that hardware.'
								width='400'
								height='287'
								description={true}
							/>
						</li>
					</ul>
				),
			},
			{
				heading: 'Security',
				content: (
					<ul>
						<li>
							Database credentials are now more robust. The
							authentication for the MongoDB is now required to be
							unique which makes it more robust.
						</li>
						<li>
							Fixed a bug where Chrome would auto-fill credentials
							on task authorization screens. Chrome no longer
							auto-fills these credentials, as intended.
						</li>
					</ul>
				),
			},
			{
				heading: 'Containment',
				content: (
					<ul>
						<li>
							Fixed a bug where the <i>Containment</i> page filter
							for compaigns did not filter correctly. The filter
							will now work as intended.
						</li>
						<li>
							Fixed a bug where the <i>Contain Parts</i> search
							button was not working on the <i>Containment</i>{' '}
							page. This button will now work as intended.
						</li>
					</ul>
				),
			},
		],
	},
];
