import React from 'react';
import { Container, Row, Col, Navbar, Nav, Button } from 'react-bootstrap';
import { navigation_footer } from '../utils/navigation_footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import {
	faFacebookSquare,
	faLinkedin,
	faTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

function NavSocialMedia(props) {
	return (
		<Button
			href={props.href}
			target='_blank'
			variant='link'
			className='link-gray-400 p-0'
		>
			<p className='p-2 mb-0'>
				<FontAwesomeIcon icon={props.icon} size='lg' />
			</p>
		</Button>
	);
}

function NavColumn(props) {
	return (
		<div className='p-0 py-3 p-md-5'>
			<p className='text-white small fw-bold'>
				{props.item.icon ? (
					<FontAwesomeIcon
						icon={props.item.icon}
						className='text-primary-light me-2'
					/>
				) : null}
				{props.item.title}
			</p>
			<div className='d-flex flex-column'>
				{props.item.children.map((link, i) => {
					return (
						<Nav.Link
							href={link.href}
							className='fw-normal d-inline-block p-0'
							style={{ width: 'max-content' }}
							key={i}
						>
							<p className='small mb-1'>
								{link.icon ? (
									<FontAwesomeIcon
										icon={link.icon}
										className='text-primary-light me-2'
									/>
								) : null}
								{!Array.isArray(link.title) ? (
									link.title
								) : (
									<>
										{link.title.map((item, j) => {
											return (
												<React.Fragment key={j}>
													{item}
													<br />
												</React.Fragment>
											);
										})}
									</>
								)}
							</p>
						</Nav.Link>
					);
				})}
			</div>
		</div>
	);
}

export default function Footer() {
	return (
		<>
			<div id='footer' className='bg-gray-800'>
				<Container>
					<Navbar
						expand='lg'
						className='align-items-start py-3 py-md-0'
						variant='dark'
					>
						{navigation_footer.map((item, i) => {
							return (
								<Col xs={12} md key={i}>
									{item.title ? (
										<NavColumn item={item} />
									) : (
										<>
											{item.children.map((child, j) => {
												return (
													<React.Fragment key={j}>
														<NavColumn
															item={child}
														/>
													</React.Fragment>
												);
											})}
										</>
									)}
								</Col>
							);
						})}
					</Navbar>
				</Container>
				<div className='bg-gray-900'>
					<Container>
						<Navbar
							className='align-items-center py-3'
							variant='dark'
						>
							<Col xs={6} md={8}>
								<div className='p-0 px-md-5'>
									<Row>
										<Col xs={12} md>
											<p className='fw-bold text-white mb-0'>
												eflex systems
											</p>
										</Col>
										<Col xs={12} md>
											<p className='small text-md-center text-gray-400 mb-0'>
												<FontAwesomeIcon
													icon={faCopyright}
													className='me-1'
												/>
												Copyright 2023 Epicor
											</p>
										</Col>
									</Row>
								</div>
							</Col>
							<Col xs={6} md={4}>
								<div className='p-0 px-md-5'>
									<div className='d-flex justify-content-end'>
										<NavSocialMedia
											href='https://www.facebook.com/eFlexsystem/'
											icon={faFacebookSquare}
										/>
										<NavSocialMedia
											href='https://www.linkedin.com/company/eflex-systems'
											icon={faLinkedin}
										/>
										<NavSocialMedia
											href='https://twitter.com/eflexsystems'
											icon={faTwitter}
										/>
										<NavSocialMedia
											href='https://www.youtube.com/user/eFlexSystems'
											icon={faYoutube}
										/>
									</div>
								</div>
							</Col>
						</Navbar>
					</Container>
				</div>
			</div>
		</>
	);
}
