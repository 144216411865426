import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

ReactDOM.render(
	<Auth0Provider
		domain={process.env.REACT_APP_DOMAIN}
		clientId='gE7pUWVz9I8eSQCc6xnv50AILxOhbuE0'
		redirectUri={process.env.REACT_APP_CALLBACK}
		audience={`https://${process.env.REACT_APP_DOMAIN}/api/v2/`}
		scope='read:users read:current_user read:users_app_metadata update:users update:users_app_metadata update:current_user_metadata'
	>
		<Helmet>
			<script
				src='https://kit.fontawesome.com/41afa11259.js'
				crossorigin='anonymous'
			/>
			<link
				defer
				href='https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap'
				rel='stylesheet'
			/>
			<link
				defer
				href='https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap'
				rel='stylesheet'
			/>
			<link
				rel='icon'
				href='https://490686.fs1.hubspotusercontent-na1.net/hubfs/490686/Logos/logo_mipCaretAlt.svg'
			></link>
			<title>eFlex Support</title>
		</Helmet>
		<BrowserRouter>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</BrowserRouter>
	</Auth0Provider>,
	document.getElementById('root')
);
