export const navigation = [
	{
		title: 'Platform',
		href: 'https://www.eflexsystems.com/platform',
		children: [
			{
				title: 'Solutions',
				children: [
					{
						title: 'Digital Work Instructions',
						href: 'https://www.eflexsystems.com/work-instruction-software',
					},
					{
						title: 'Track & Trace',
						href: 'https://www.eflexsystems.com/flexible-solutions/lean-manufacturing-software/manufacturing-traceability-quality-management-software-to-track-trace',
					},
					{
						title: 'Quality',
						href: 'https://www.eflexsystems.com/quality',
					},
					{
						title: 'Kitting',
						href: 'https://www.eflexsystems.com/kitting',
					},
					{
						title: 'Preventative Maintenance',
						href: 'https://www.eflexsystems.com/preventative-maintenance',
					},
					{
						title: 'Andon',
						href: 'https://www.eflexsystems.com/andon',
					},
					{
						title: 'OEE',
						href: 'https://www.eflexsystems.com/oee',
					},
					{
						title: 'Business Intelligence',
						href: 'https://www.eflexsystems.com/business-intelligence',
					},
				],
			},
			{
				title: 'Device Integrations',
				children: [
					{
						title: 'Barcode Reader',
						href: 'https://www.eflexsystems.com/device-integration/barcode-readers',
					},
					{
						title: 'Torque Tools',
						href: 'https://www.eflexsystems.com/device-integration/torque-tools',
					},
					{
						title: 'I/O Devices',
						href: 'https://www.eflexsystems.com/device-integration/io-devices',
					},
					{
						title: 'Webcams',
						href: 'https://www.eflexsystems.com/device-integration/webcams',
					},
					{
						title: 'Vision Systems',
						href: 'https://www.eflexsystems.com/device-integration/vision-systems',
					},
					{
						title: 'Gauges',
						href: 'https://www.eflexsystems.com/device-integration/gauges',
					},
					{
						title: 'Printers',
						href: 'https://www.eflexsystems.com/device-integration/printers',
					},
					{
						title: 'Augmented Reality',
						href: 'https://www.eflexsystems.com/device-integration/augmented-reality',
					},
					{
						title: 'Badge Readers & Biometrics',
						href: 'https://www.eflexsystems.com/device-integration/badge-readers',
					},
					{
						title: 'PLCs',
						href: 'https://www.eflexsystems.com/device-integration/plcs',
					},
					{
						title: 'Node-RED Flow',
						href: 'https://www.eflexsystems.com/node-red-flow',
					},
				],
			},
		],
	},
	{
		title: 'Company',
		href: 'https://www.eflexsystems.com/platform',
		children: [
			{
				title: 'Learn about eFlex',
				children: [
					{
						title: 'About Us',
						href: 'https://www.eflexsystems.com/about/about-eflex-systems',
					},
					{
						title: 'Blog',
						href: 'https://www.eflexsystems.com/lean-manufacturing-blog',
					},
					{
						title: 'Upcoming Events',
						href: 'https://www.eflexsystems.com/upcoming-events',
					},
				],
			},
		],
	},
	{
		title: 'Resources',
		href: 'https://www.eflexsystems.com/resource-library',
	},
	{
		title: 'Support',
		href: 'https://www.eflexsystems.com/platform',
		children: [
			{
				title: 'How can we help?',
				children: [
					{
						title: 'Client Portal',
						href: 'https://eflexsupport.com/',
					},
					{
						title: 'Contact Support',
						href: 'https://www.eflexsystems.com/contact-customer-support',
					},
					{
						title: 'Open a Ticket',
						href: 'https://epiccare.epicor.com/epiccare',
					},
				],
			},
		],
	},
];
